import { InvalidMessage } from '../InvalidMessage';
import { HelpText } from '../HelpText';
import React, { useImperativeHandle, useRef, useState } from 'react';
import { useInputField } from '../../hooks/useInputField';
import {
    StyledCheckboxWrapper,
    StyledCheckboxElement,
    StyledCheckbox,
    StyledCheckboxIndicator,
    StyledLabel,
    StyledCheckboxHelpTexts,
    StyledRichtext,
    StyledCheckmarkWrapper,
} from './styled';
import { RawHtml } from '~/shared/components/RawHtml';
import Checkmark from '$icons/checkmark.svg';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

export type CheckboxProps = Omit<InputProps, 'placeholder' | 'value'> & {
    /**
     * Adds a label to the input field. This is required for accessibilty.
     */
    label?: string;

    /**
     * Adds a component as label to the input field..
     */
    labelRichtext?: string;

    /**
     * Add an additional help text below the input field.
     */
    helpText?: string;

    /**
     * Add an additional help text below the input field.
     */
    invalidMessage?: string;

    /**
     * Set styling to indicate input is invalid.
     * Also shows the `invalidMessage` if provided
     */
    isInvalid?: boolean;

    /**
     * An image url to use on checkmark
     */
    image?: string;

    /**
     * Toggle between checkbox or radio
     */
    type?: 'checkbox' | 'radio';

    /**
     * Adding a readMore Link on label
     */
    readMore?: React.ReactNode;
};

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
    (
        {
            label,
            labelRichtext,
            helpText,
            invalidMessage,
            isInvalid,
            id,
            onChange,
            disabled,
            type = 'checkbox',
            checked,
            image,
            readMore,
            ...rest
        },
        ref,
    ) => {
        const [isValidityValid, setIsValidityValid] = useState(true);
        const inputRef = useRef<HTMLInputElement>(null);

        const {
            fieldId,
            helpTextId,
            invalidMessageId,
            describedById,
            showHelpText,
            showInvalidMessage,
        } = useInputField({
            id: id || rest.name,
            helpText,
            invalidMessage,
            isInvalid,
        });

        const isValid = !isInvalid && isValidityValid;

        const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            setIsValidityValid(!!inputRef.current?.validity.valid);
            onChange && onChange(event);
        };

        useImperativeHandle(ref, () => inputRef.current as HTMLInputElement, [ref]);

        return (
            <div key={fieldId}>
                <StyledCheckboxWrapper valid={isValid}>
                    <StyledCheckboxElement round={type === 'radio'} image={image}>
                        <StyledCheckbox
                            type={type}
                            id={fieldId}
                            ref={inputRef}
                            aria-describedby={describedById}
                            onChange={onChangeHandler}
                            disabled={disabled}
                            {...rest}
                            checked={checked}
                        />
                        <StyledCheckboxIndicator>
                            {type === 'checkbox' ? (
                                <StyledCheckmarkWrapper checked={checked}>
                                    <Checkmark />
                                </StyledCheckmarkWrapper>
                            ) : null}
                        </StyledCheckboxIndicator>
                    </StyledCheckboxElement>
                    {label ? (
                        <StyledLabel htmlFor={fieldId} title={label}>
                            {`${label} `}
                            {readMore && readMore}
                        </StyledLabel>
                    ) : null}

                    {labelRichtext ? (
                        <StyledRichtext valid={isValid}>
                            <RawHtml html={labelRichtext} />
                        </StyledRichtext>
                    ) : null}
                </StyledCheckboxWrapper>
                {(showInvalidMessage || showHelpText) && (
                    <StyledCheckboxHelpTexts>
                        {showInvalidMessage ? (
                            <InvalidMessage id={invalidMessageId} children={invalidMessage} />
                        ) : null}
                        {showHelpText ? <HelpText id={helpTextId} children={helpText} /> : null}
                    </StyledCheckboxHelpTexts>
                )}
            </div>
        );
    },
);
