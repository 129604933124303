import styled from '@emotion/styled';
import { ifNotProp, ifProp } from 'styled-tools';
import { RawHtml } from '~/shared/components/RawHtml';

export const StyledCheckbox = styled.input({
    top: 0,
    left: 0,
    width: '100%',
    cursor: 'inherit',
    height: '100%',
    margin: 0,
    opacity: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
});

export const StyledLabel = styled.label(({ theme }) => ({
    color: theme.colors.black,
    fontSize: theme.fontSizes['2xs'],
    display: 'block',
    cursor: 'pointer',
    padding:
        theme.direction === 'rtl' ? `4px ${theme.spaces[3]} 0 0 ` : `4px 0 0 ${theme.spaces[3]}`,
    lineHeight: 1.6,
    minHeight: 25,
}));

export const StyledCheckboxIndicator = styled.div(({ theme }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '0.4rem',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    transition: `background-color ${theme.animations.timingLong} ${theme.animations.easeInOutQuad}, transform ${theme.animations.timingShort} ${theme.animations.easeInOutQuad}`,
}));

export const StyledCheckmarkWrapper = styled.span<{ checked?: boolean }>(
    ({ theme }) => ({
        width: 0,
        marginLeft: -16,
        height: 16,
        position: 'relative',
        display: 'inline-block',
        overflow: 'hidden',
        transitionProperty: `width, margin`,
        transitionDuration: theme.animations.timingLong,
        transitionTimingFunction: theme.animations.easeInOutQuad,

        svg: {
            position: 'absolute',
            width: 16,
            height: 16,
            left: 0,
            top: 0,

            path: {
                fill: 'white',
            },
        },
    }),
    ifProp('checked', {
        width: 16,
        marginLeft: 0,
    }),
);

export const StyledCheckboxElement = styled.div<{ round: boolean; image?: string }>(
    ({ theme }) => ({
        position: 'relative',
        backgroundColor: theme.colors.grey05,
        boxSizing: 'border-box',
        width: 24,
        height: 24,
        cursor: 'pointer',
        borderRadius: '0.4rem',
        overflow: 'hidden',
    }),
    ifProp('round', {
        borderRadius: '100%',

        [`${StyledCheckboxIndicator}`]: {
            borderRadius: '100%',
            top: '0.7rem',
            right: '0.7rem',
            bottom: '0.7rem',
            left: '0.7rem',
            transform: 'scale3d(0, 0, 1)',
            zIndex: 1,
        },
    }),
    ifProp('image', ({ image }) => ({
        '&:before': {
            content: '""',
            backgroundImage: `url(${image})`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 0,
        },
    })),
);

export const StyledCheckboxWrapper = styled.div<{ valid: boolean }>(
    ({ theme }) => ({
        display: 'grid',
        gridTemplateColumns: `25px 1fr`,
        ':hover, :focus-within': {
            [`${StyledCheckboxIndicator}`]: {
                backgroundColor: theme.colors.grey20,
                transform: 'scale3d(1, 1, 1)',
                filter: 'opacity(0.6)',
            },
            [`input:checked + ${StyledCheckboxIndicator}`]: {
                backgroundColor: theme.colors.primary,
                transform: 'scale3d(1, 1, 1)',
                filter: 'opacity(1)',
            },
        },

        [`input:checked + ${StyledCheckboxIndicator}`]: {
            backgroundColor: theme.colors.primary,
            transform: 'scale3d(1, 1, 1)',
            pointerEvents: 'none',
            zIndex: 1,
        },
    }),
    ifNotProp('valid', ({ theme }) => ({
        [`${StyledLabel}`]: {
            color: theme.colors.negative,
        },
        [`${RawHtml}`]: {
            color: theme.colors.negative,
        },
    })),
);

export const StyledRichtext = styled.div<{ valid: boolean }>(({ theme, valid }) => ({
    paddingLeft: theme.spaces[3],
    p: {
        lineHeight: '24px',
        paddingTop: 2,
        margin: 0,
        color: valid ? theme.colors.black100 : `${theme.colors.negative} !important`,
    },
}));

export const StyledCheckboxHelpTexts = styled.div(({ theme }) => ({
    marginLeft: `calc(25px + ${theme.spaces[3]})`,
}));
