import styled, { CSSObject } from '@emotion/styled';

export const StyledHelpText = styled.div(
    ({ theme }) =>
        ({
            ...theme.fontVariants.bodySm,
            lineHeight: theme.lineHeights.compact,
            color: theme.colors.grey60,
            marginTop: 8,
            padding: `0 ${theme.spaces[6]}`,
        } as CSSObject)
);
